<script setup lang="ts">
import type { HeroSplit2Storyblok } from '~/types/storyblok'

const props = defineProps<{
  blok: HeroSplit2Storyblok
}>()

function reverseClass(small: boolean) {
  if (props.blok.reverse) {
    return {
      'order-1': small,
      'order-2': !small,
    }
  }
}
</script>

<template>
  <div v-editable="blok" class="grid break-inside-avoid grid-cols-1 gap-6 xl:grid-cols-5">
    <StoryblokComponent :blok="blok.blocks[0]" class="xl:col-span-3" :class="reverseClass(false)" />
    <StoryblokComponent :blok="blok.blocks[1]" class="xl:col-span-2" :class="reverseClass(true)" />
  </div>
</template>
